import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Logo from 'componentsNew/icons/logo'

import logo from '../../images/expert-rating-logo.png'
import logoGray from '../../images/logo-gray.svg'
import logoAlt from '../../images/logo-gray-alt.svg'

const welcomeBanners = [
  {
    rx: /PCH/,
    title: 'Publishers Clearing House',
    logo:
      '//static1.squarespace.com/static/57b34a623e00be1e7b82c8f0/t/5b96825721c67c935ccd65a9/1536590424458/Stacked-white.png?format=1000w',
  },
]

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showWelcomeBanner: false,
      welcomeBanner: '',
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.captive.src !== prevProps.captive.src) {
      if (welcomeBanners.some(({ rx }) => rx.test(this.props.captive.src))) {
        const banner = welcomeBanners.find(({ rx }) => rx.test(this.props.captive.src))
        this.setState({
          showWelcomeBanner: true,
          welcomeBanner: banner,
        })
      }
    }
  }

  render() {
    const {
      phone,
      hideCta,
      showGen,
      className,
      grayLogo,
      altLogo,
      buttonClassName,
      noIntro = false,
      type = 'default',
      children,
      ctaText,
      TTYBig,
    } = this.props

    let headerLogo = grayLogo ? logoGray : logo
    headerLogo = altLogo ? logoAlt : headerLogo

    const ctaRenderedText =
      ctaText === '' ? 'Speak with a licensed <br />insurance agent!' : ctaText

    return (
      <div
        className={clsx(
          'top-wrapper',
          noIntro && 'top-wrapper--no-intro',
          type && `top-wrapper--${type}`,
        )}
      >
        {this.state.showWelcomeBanner && (
          <div className="welcome">
            <div className="container">
              <div className="welcome_holder">
                <img
                  src={this.state.welcomeBanner.logo}
                  alt={this.state.welcomeBanner.title}
                />
                <p>
                  Welcome friends of <strong>{this.state.welcomeBanner.title}</strong>
                </p>
              </div>
            </div>
          </div>
        )}

        {/* {showGen && (
          <div className="gen3">
            <div className="container">
              <div className="g3cm_holder" data-module="1259" data-showoninit="true">
                <p>
                  A live agent in your area is standing by. For your free quote, call now.
                </p>
                <a href={`tel:${phone}`} className="btn with-icon header-cta-btn">
                  <span className="icon-phone" /> <span className="text">{phone}</span>
                </a>
              </div>
            </div>
          </div>
        )} */}

        <div className={clsx('header-wrapper', hideCta && 'cta-hidden')}>
          <header id="header" className={className}>
            <div className="container">
              <strong className="logo">
                {this.props.isR4M ? (
                  <Logo />
                ) : (
                  <img src={headerLogo} alt="MedicareCompanion.com" />
                )}
              </strong>
              {!hideCta && (
                <div className="cta-holder">
                  <span
                    className={clsx(
                      'text',
                      'hidden-mobile',
                      type === 'post' && 't-black',
                    )}
                    dangerouslySetInnerHTML={{ __html: ctaRenderedText }}
                  />
                  <a
                    href={`tel:${phone}`}
                    className={clsx(
                      buttonClassName,
                      'btn with-icon header-cta-btn',
                      TTYBig && 'TTYBig',
                    )}
                  >
                    <span className="icon-phone" /> <span className="text">{phone}</span>
                  </a>
                </div>
              )}
              {children}
            </div>
          </header>
        </div>
      </div>
    )
  }
}

Header.propTypes = {
  phone: PropTypes.string,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  hideCta: PropTypes.bool,
  showGen: PropTypes.bool,
  grayLogo: PropTypes.bool,
  altLogo: PropTypes.bool,
  type: PropTypes.string,
  ctaText: PropTypes.string,
}

Header.defaultProps = {
  phone: ``,
  className: ``,
  buttonClassName: ``,
  ctaText: '',
  hideCta: false,
  showGen: false,
  grayLogo: false,
  altLogo: false,
  type: 'default',
  isR4M: false,
}

export default Header
